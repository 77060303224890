<template>
  <v-app>
    <v-app-bar flat app color="#fff">
      <!-- -->
      <nav-header></nav-header>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import navHeader from "./nav-header/nav-header.vue";
import navgationDrawer from "./navgation-drawer/navgation-drawer.vue";
export default {
  components: {
    navHeader,
    navgationDrawer,
  },
  created() {
    this.$store.dispatch("getUserInfo");
  },
};
</script>

<style lang="scss" scoped></style>
