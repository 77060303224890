<style lang="scss">
#e-header {
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 15%) !important;
  z-index: 110;
}
</style>
<template>
  <div class="pos-r header al-c space-btw">
    <img height="25" src="../../assets/nav-logo.svg" alt="" />
    <img
      class="cursor-p"
      width="22"
      src="../../assets/nav-logout.svg"
      @click="handleLogout"
      alt=""
    />
  </div>
</template>

<script>
export default {
  methods: {
    handleLogout() {
      localStorage.clear();
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
}
.title {
  font-weight: bold;
}
.btn {
}
</style>
